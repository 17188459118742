import { Checkbox } from '@hudl/uniform-ui-components';
import React, { useState } from 'react';
import {
  PageHero,
  PageWithSubNav,
  Paragraph,
  Section,
} from '../../../../components';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const ChecklistItem = ({ text, helpText }) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <React.Fragment>
      <Checkbox
        label={text}
        onChange={() => setIsChecked(prev => !prev)}
        isChecked={isChecked}
        className={
          helpText ? 'uni-margin--half--bottom' : 'uni-margin--one--bottom'
        }
        size="large"
      />
      {helpText && (
        <Paragraph className="uni-text--subtle uni-text--small uni-margin--half--bottom uni-margin--one-and-half--left">
          {helpText}
        </Paragraph>
      )}
    </React.Fragment>
  );
};

const SharedPage = ({
  pageType,
  visualItems,
  interactiveItems,
  automationItems,
}) => {
  return (
    <PageWithSubNav
      pageType={pageType}
      title="Checklists"
      designCode
      subnav="guidelines">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Accessibility"
        tierThree="Checklists"
      />

      {visualItems && (
        <Section title="Visual">
          <div>
            {visualItems.map(item => (
              <ChecklistItem {...item} />
            ))}
          </div>
        </Section>
      )}

      {interactiveItems && (
        <Section title="Interactive">
          <div>
            {interactiveItems.map(item => (
              <ChecklistItem {...item} />
            ))}
          </div>
        </Section>
      )}

      {automationItems && (
        <Section title="Automated">
          <div>
            {automationItems.map(item => (
              <ChecklistItem {...item} />
            ))}
          </div>
        </Section>
      )}
    </PageWithSubNav>
  );
};

export default SharedPage;
