/* eslint-disable max-len */
import React from 'react';
import SharedPage from './shared';
import { Link } from '../../../../components';

const IndexPage = ({}) => {
  const visualItems = [
    {
      text: 'Use Uniform colors whenever possible.',
    },
    {
      text: (
        <React.Fragment>
          Use the&nbsp;
          <Link href="https://www.figma.com/community/plugin/732603254453395948/Stark">
            Figma Stark plugin
          </Link>
          &nbsp; Contrast tool to verify that any custom colors meet our color
          contrast requirements.
        </React.Fragment>
      ),
    },
    {
      text: (
        <React.Fragment>
          Use the&nbsp;
          <Link href="https://www.figma.com/community/plugin/732603254453395948/Stark">
            Figma Stark plugin
          </Link>
          &nbsp; Visual Simulator tool to test interfaces with colorblind
          simulators.
        </React.Fragment>
      ),
    },
    {
      text:
        'Use another indicator in addition to color to show a change in state (icons, borders, etc.).',
    },
    {
      text:
        'Use logical labels, help text and error messages in form elements.',
    },
    {
      text:
        'Use clear, familiar words with minimal abbreviations, acronyms and jargon.',
    },
    {
      text: (
        <React.Fragment>
          Use the&nbsp;
          <Link href="https://www.figma.com/community/plugin/892114953056389734/Text-Resizer---Accessibility-Checker">
            Figma Text Resizer plugin
          </Link>
          &nbsp; to verify that text still shows at 200%.
        </React.Fragment>
      ),
    },
    {
      text:
        'Include mobile responsive designs with matching functionality, if applicable.',
    },
    {
      text: 'Consider if text will be internationalized and plan accordingly.',
    },
  ];

  const interactiveItems = [
    {
      text: (
        <React.Fragment>
          Use the&nbsp;
          <Link href="https://www.figma.com/file/kBPRlhqT74PfaKbzZIhIfG/Uniform-Annotations?node-id=2%3A58&viewport=415%2C124%2C0.71">
            Uniform Tab Order Annotation component
          </Link>
          &nbsp;to show tab order in mockups if it isn't obvious.
        </React.Fragment>
      ),
    },
    {
      text: 'Add focus states to any interactive elements.',
    },
    {
      text:
        'Strive for mobile touch targets of at least 44px x 44px, except when placed inline.',
      helpText:
        "This is a WCAG 2.1 AAA rule, so it's not always required, but should be the goal.",
    },
  ];

  return (
    <SharedPage
      pageType="design"
      visualItems={visualItems}
      interactiveItems={interactiveItems}
    />
  );
};

export default IndexPage;
